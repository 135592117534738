import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slide.css";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SliderProducts } from "../../data/products";

const Slide = () => {
  return (
    <div className="s-container">
      <Swiper
      breakpoints={{
        640: {
          slidesPerView : 3,
        },
        0: {
          slidesPerView : 1,
        },
      }}
        modules={[Pagination, Navigation]}
        className="my-swiper"
        navigation={true}
        loopFillGroupWithBlank={true}
        slidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop={true}
      >
        {SliderProducts.map((product, i) => (
          <SwiperSlide>
            <div className="left-s">
              <div className="name">
                <span>{product.name}</span>
                <span>{product.detail}</span>
              </div>
              <span>{product.price}</span>
              <div>Shop Now</div>
            </div>
            <img src={product.img} alt="" className="img-p" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slide;
