import React from "react";
import css from "./Testimonials.module.css";
import img from "../../assets/testimonialHero.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { TestimonialsData } from "../../data/testimonials";
const Testimonials = () => {
  return (
    <div className={css.testimonials}>
      <div className={css.wrapper}>
        <div className={css.container}>
          <span>Top Rated</span>
          <span>
            Seedily say has suitable and boy. Exercice joy man children
            rejoiced.
          </span>
        </div>
        <img src={img} alt="" />
        <div className={css.container}>
          <span>100K</span>
          <span>Happy Customers with us</span>
        </div>
      </div>

      <span className={css.reviews}>Reviews</span>

      <div className={css.carousal}>
        <Swiper
          slidesPerGroup={1}
          slidesPerView={3}
          spaceBetween={20}
          className={css.tcarsoual}
          breakpoints={{
            860: {
              slidesPerView : 3
            },
            640: {
              slidesPerView : 2
            },
            0: {
              slidesPerView : 1
            }
          }}
        >
          {TestimonialsData.map((data, i) => (
            <SwiperSlide>
              <div className={css.testimonial}>
                <img src={data.image} alt="" />
                <span>{data.comment}</span>
                <hr />
                <span>{data.name}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
